
/**По документации импорт делается так, но есть ошибки, поэтому 
 * импорт сделал через head там 2 ссылки на библиотеки
 * 
 * import mmrgl from "mmr-gl";
 * import "mmr-gl/dist/mmr-gl.css"; 
 */

import store from "../../store/store";
import { openPopup } from "../../store/index";
const REACT_APP_VKMAPS_API_KEY = process.env.REACT_APP_VKMAPS_API_KEY;

import markerRestaurant1 from "../../assets/icon/marker-restaurant-1.svg";
import markerRestaurant2 from "../../assets/icon/marker-restaurant-2.svg";
import markerRestaurant3 from "../../assets/icon/marker-restaurant-3.svg";
import markerRestaurant4 from "../../assets/icon/marker-restaurant-4.svg";
import markerRestaurant5 from "../../assets/icon/marker-restaurant-5.svg";

class MapVKSingleton {
  constructor() {
    if (!MapVKSingleton.instance) {
      this.map = null;
      MapVKSingleton.instance = this;
      console.log("render VKmap");
      this.markers = { first: null, second: null };
    }
    return MapVKSingleton.instance;
  }

  _getIcon(priority) {
    const icon = {
      1: markerRestaurant1,
      2: markerRestaurant2,
      3: markerRestaurant3,
      4: markerRestaurant4,
      5: markerRestaurant5,
    };

    if (!icon[priority]) {
      return markerRestaurant5;
    }

    return icon[priority];
  }

  _getHtmlMarker(priority = 1, title = "", id = "", food = "Не определено") {
    const imgUrl = this._getIcon(priority);

    const titleMarker = document.createElement("div");
    titleMarker.style.position = "absolute";
    titleMarker.style.top = "-20px";
    titleMarker.style.textWrap = "nowrap";
    titleMarker.style.color = "black";
    titleMarker.innerHTML = title;   
    titleMarker.style.backgroundColor = "#ffffff";
    titleMarker.style.borderColor = "rgb(217, 217, 217)";
    titleMarker.style.borderStyle = "solid";
    titleMarker.style.borderWidth = "1px";
    titleMarker.style.borderRadius = "20px";
    titleMarker.style.padding = "2px";
    titleMarker.style.display = "none";

    const div = document.createElement("div");
    // div.style.width = "46px";
    // div.style.height = "60px";

    div.style.width = "32px";
    div.style.height = "42px";

    div.style.backgroundImage = `url(${imgUrl})`;
    div.style.backgroundSize = "cover";

    div.addEventListener("mouseover", function () {      
      titleMarker.style.display = "block";
    });

    div.addEventListener("mouseout", function () {
      titleMarker.style.display = "none";
     
    });

    div.addEventListener("click", function () {
      store.dispatch(openPopup(id));
    });

    if(food.includes("рублей")) {
      const isFood = document.createElement("div");
    isFood.style.position = "absolute";
    isFood.style.height = "12px";
    isFood.style.width = "12px";
    isFood.style.top = "-4px";
    isFood.style.borderRadius = "50px";
    isFood.style.backgroundColor = "#0972e1";
    div.appendChild(isFood);
    }   


    div.appendChild(titleMarker);
    return div;
  }

  _getColorMarcer(type) {
    let color = "";
    switch (type) {
      case "first":
        color = "red";
        break;
      case "second":
        color = "green";
        break;
      default:
        color = "#3FB1CE";
        break;
    }

    return color;
  }

  // центрирование координат
  centerMapOnCoordinates(coordinates) {
    if (this.map) {
      this.map.flyTo({
        center: coordinates,
        essential: true, // гарантирует плавное перемещение
      });
    }
  }

  deleteMarkerFotType(type) {
    if (this.markers[type]) {
      this.markers[type].remove();
      this.markers[type] = null;
    }
  }

  // Функция для добавления нового маркера на карту
  addMarkerToMap(coordinates, type) {
    this.deleteMarkerFotType(type);

    let color = this._getColorMarcer(type);

    if (this.map) {
      const marker = new mmrgl.Marker({ color: color })
        .setLngLat(coordinates)
        .addTo(this.map);
      this.markers[type] = marker;
    }
  }

  _addMarkerRestaurants(markers) {
    markers?.map((i) => {
      try {
        let { priority, title, id, food } = i;

        console.log(i);
        const htmlMarrker = this._getHtmlMarker(priority, title, id, food);

        const lat = i.point.coordinates[1];
        const lon = i.point.coordinates[0];

        new mmrgl.Marker({
          element: htmlMarrker,
          anchor: "bottom",
        })
          .setLngLat([+lat, +lon])
          // .setPopup( new mmrgl.Popup().setHTML( `<div>Информация</div>` ))

          .addTo(this.map);
      } catch (e) {
        console.error(e);
      }
    });
  }

  initializeMap(markers) {
    mmrgl.accessToken = REACT_APP_VKMAPS_API_KEY;
    this.map = new mmrgl.Map({
      container: "map",
      zoom: 8,
      center: [37.6165, 55.7505],
      style: "mmr://api/styles/main_style.json",
      hash: true,
    });

    this._addMarkerRestaurants(markers);
  }

  removeMap() {
    if (this.map) {
      this.map.remove();
    }
  }

  // расшифровка маршрута по документации
  _decodePolyline(polyline) {
    let index = 0,
      lat = 0,
      lng = 0,
      coordinates = [],
      shift = 0,
      result = 0,
      byte = null,
      latitude_change,
      longitude_change,
      factor = 1000000;

    while (index < polyline.length) {
      byte = null;
      shift = 0;
      result = 0;

      do {
        byte = polyline.charCodeAt(index++) - 63;
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);

      latitude_change = result & 1 ? ~(result >> 1) : result >> 1;
      shift = result = 0;

      do {
        byte = polyline.charCodeAt(index++) - 63;
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);

      longitude_change = result & 1 ? ~(result >> 1) : result >> 1;

      lat += latitude_change;
      lng += longitude_change;

      coordinates.push([lng / factor, lat / factor]);
    }

    return coordinates;
  }

  // удаление старого роута
  deleteRout() {
    if (this.map.getLayer("route")) {
      this.map.removeLayer("route");
    }
    if (this.map.getSource("route")) {
      this.map.removeSource("route");
    }
  }

  buildRoutOnMap(shape) {
    this.deleteRout();

    let decodedRoute = this._decodePolyline(shape);

    // Добавление линии, представляющей маршрут
    this.map.addLayer({
      id: "route",
      type: "line",
      source: {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: decodedRoute,
          },
        },
      },
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#888",
        "line-width": 8,
      },
    });
  }
}

const map = new MapVKSingleton();

export default map;
