import vkTypeTranslate from "shared/helpers/vkTypeTranslate";

const VKMAP = process.env.REACT_APP_VKMAPS_API_KEY;

export default function useApiVK() {
  let timeout;
  let currentValue;
  // 1 функция подсказка адреса
  const suggestVK = (value, callback) => {
    console.log("------------SUGGEST VK --------------");
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;
    const fake = (value) => {
      let URL_SUGGEST = `https://maps.vk.com/api/suggest?limit=3&fields=address_details,ref&q=${value}&api_key=${VKMAP}&location=55.753672,37.623016`;

      fetch(URL_SUGGEST)
        .then((response) => response.json())
        .then((d) => {
          if (currentValue === value) {
            const { results } = d;
            function isValue(value) {
              if (value) {
                return value;
              } else {
                return "";
              }
            }

            console.log("suggest", results);
            const data = results.map((item) => ({
              key: Math.random(),
              value: `${isValue(item.address_details?.region)} ${isValue(
                item.address_details?.street
              )} ${isValue(item.address_details?.building)} ${isValue(
                item.address_details?.locality
              )}`,
              label: `${isValue(item.address_details?.region)} ${isValue(
                item.address_details?.street
              )} ${isValue(item.address_details?.building)} ${isValue(
                item.address_details?.locality
              )}`,
              ref: item.ref,
            }));
            callback(data);
          }
        });
    };

    if (value) {
      timeout = setTimeout(fake, 300);
    } else {
      callback([]);
    }
    fake(value);
  };

  const searchPlacesInterest = (value, callback) => {
    console.log("------------ Поиск мест интереса VK --------------");
    const serch = (value) => {
      fetch(
        `https://maps.vk.com/api/places?limit=6&q=${value}&api_key=${VKMAP}&location=55.753672,37.623016`
      )
        .then((response) => response.json())
        .then((d) => {
          const { results } = d;
          console.log("Places", results);
          const data = results.map((item) => ({
            key: Math.random(),
            arrLatLon: [item.pin[1], item.pin[0]],
            //label: <div>{item.name} <br></br><div style={{color: '#b2b3b8'}}>{item.address_details.region}<br></br>{item.address_details.subregion}<br></br>{item.address_details.suburb}</div></div>
            label: `${item.name} ${item.address_details?.region} ${item.address_details?.subregion} ${item.address_details?.suburb}`,
            type: vkTypeTranslate(item.type),
          }));

          callback(data);
        });
    };
    serch(value);
  };





// 1 функция подсказка адреса
const searchVKbyRef = (value, callback) => { 

    let URL_SUGGEST = `https://maps.vk.com/api/search?q=${value}&api_key=${VKMAP}`;

    fetch(URL_SUGGEST )
      .then((response) => response.json())
      .then((d) => {                 
          const { results } = d;          
          callback(results[0]);        
        
      });
  };

  const getPointRout = (arrFirst, arrSecond, callback) => {     
       

       const url = `https://maps.vk.com/api/directions?api_key=${VKMAP}`;
       
    const params = {
      locations: 
      [
        { lat: arrFirst[1], lon: arrFirst[0] },
        { lat: arrSecond[1], lon: arrSecond[0] }
      ], 

      costing: "auto",
      costing_options: {
        auto: {
          use_border_crossing: 0
        }
      },
      units: "kilometers",
      id: "my_route"
    };
    
    fetch(url, {
      method: "POST",
      body: JSON.stringify(params)
    })
      .then(response => response.json())
      .then(data => {

    const infoForRout = {distanse: data.trips[0].trip.summary.length, time: data.trips[0].trip.summary.time}
       
    const shape = data.trips[0].trip.legs[0].shape;
    callback(shape, infoForRout);
   // console.log(data)
    
      } )
      .catch(error => console.error(error));
    }

  return { suggestVK, searchPlacesInterest, searchVKbyRef, getPointRout };
}
