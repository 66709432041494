const VKMAP = process.env.REACT_APP_VKMAPS_API_KEY;

export default function useApiVK () {



//console.log("Работа API сервиса VK");

let timeout;
let currentValue;
// 1 функция подсказка адреса
const suggestVK = (value, callback) => {
  console.log("------------SUGGEST VK --------------");
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  } 
  currentValue = value;
  const fake = (value) => {
    let URL_SUGGEST = `https://maps.vk.com/api/suggest?limit=3&fields=address_details&q=${value}&api_key=${VKMAP}&location=55.753672,37.623016`;

    fetch(URL_SUGGEST )
      .then((response) => response.json())
      .then((d) => {
        if (currentValue === value) {          
          const { results } = d;
          function isValue (value) {
            if(value) {
              return value;
            } else {
              return "";
            }
          }          
          const data = results.map((item) => ({ 
            key: Math.random(),          
            value: `${isValue(item.address_details.region)} ${isValue(item.address_details.street)} ${isValue(item.address_details.building)} ${isValue(item.address_details.locality)}`,
            label: `${isValue(item.address_details.region)} ${isValue(item.address_details.street)} ${isValue(item.address_details.building)} ${isValue(item.address_details.locality)}`,
          }));          
          callback(data);
        }
      });
  };

  if (value) {
    timeout = setTimeout(fake, 300);
  } else {
    callback([]);
  }
  fake(value);
};


const searchPlacesInterest = (value, callback) => {
    console.log("------------ Поиск мест интереса VK --------------");
    const serch = (value) => {
      
      fetch(`https://maps.vk.com/api/places?limit=3&q=${value}&api_key=${VKMAP}&location=55.753672,37.623016`)
      .then((response) => response.json())
      .then((d) => {
        const { results } = d;      
        const data = results.map((item) => ({
          key: Math.random(), 
          value: `${item.pin[1]},${item.pin[0]}`,
          //label: <div>{item.name} <br></br><div style={{color: '#b2b3b8'}}>{item.address_details.region}<br></br>{item.address_details.subregion}<br></br>{item.address_details.suburb}</div></div>
          label: `${item.name} ${item.address_details.region} ${item.address_details.subregion} ${item.address_details.suburb}`
  
         
  
        }))
  
        callback(data);
        
  
      })
    }
  serch(value)
    
  
  }




    return {suggestVK, searchPlacesInterest}
}