import styles from "./ImputOptionPlaces.module.css"
import { Button, Typography } from 'antd';

export default function ImputOptionPlaces({ data, handle, setValueInput, type,  setCoord, clear}) {
  function onHandle() {  
    const {onHandle} = handle();    
    onHandle(data, type, setCoord, clear);
    setValueInput({data:data.label, type: type} );   
 
 }
  return (
    <div type="dashed" className={styles.wrapper} onClick={()=> onHandle()} >
      <div >{data.label}</div>
      <Typography.Text italic>{data.type}</Typography.Text>
    </div>
  );
}
