import { createSlice } from "@reduxjs/toolkit";




const searchRestaurantsSlice1 = createSlice({
  name: "widgetMap",
  initialState: {
      first: {
      input: "",
      coordinates: [],
      restaurants: [],
      suggest: [],
      places: []
    },
    second: {
      input: "",
      coordinates: [],
      restaurants: [],
      suggest: [],
      places: []
    },    

    infoRout: {
      distanse: null,
      time: null,
    }
  },
  reducers: {
    setRestaurants: (state, action) => {
      const {type, data} = action.payload;
      state[type].restaurants = data;       
     },
     setSuggest: (state, action) => {
      const {type, data} = action.payload;
      state[type].suggest = data;           
     },
     setPlaces: (state, action) => { 
      const {type, data} = action.payload;
      state[type].places = data;          
     },
     setInput: (state, action) => {
      const {type, data} = action.payload;      
      state[type].input = data;
     },
     setCoordinates: (state, action) => { 
      const {type, data} = action.payload;
      state[type].coordinates = data;  
      console.log(state[type].coordinates);        
     },

     clearLists: (state, action) => {
      const {type} = action.payload;
      state[type].restaurants = [];
      state[type].suggest = [];
      state[type].places = [];
      //console.log( state[type]);
     },

     clearInput: (state, action) => {
      const {type} = action.payload;
      state[type].input = "";
      state[type].coordinates = [];
      state.infoRout =  {
        distanse: null,
        time: null,
      }
     },

     setInfoAboutRout: (state, action) => {
      const {distanse, time} = action.payload;

      state.infoRout =  {
        distanse: distanse,
        time: time,
      }

    //  console.log(state.infoRout);

     },
    setStart: (state, action) => {
     // state.value = action.payload;
     console.log(state, action);     
    },
  },
});

export const { setStart, setRestaurants, setSuggest, setPlaces, setInput, setCoordinates, clearLists, clearInput, setInfoAboutRout} = searchRestaurantsSlice1.actions;

export default searchRestaurantsSlice1.reducer;
