import map from "shared/MapVKSingleton/MapVKSingleton";


const handlePlaces = () => {
  
  
  const onHandle = (data, type, setCoord, clear) => {
    
    

    function centrMap(arrLatLon) {
      map.centerMapOnCoordinates(arrLatLon);
      map.addMarkerToMap(arrLatLon, type);
    }

    function index(data) {
      let arrLatLon = data.arrLatLon;
      centrMap([arrLatLon[1], arrLatLon[0]]);
      
      setCoord({type: type , data: [arrLatLon[1], arrLatLon[0]] });
      clear({type: type});
    }

    index(data);
  };

  return { onHandle };
};

export default handlePlaces;
